.profileButton {
  all: initial;
  border-radius: 100px;
  width: 91px;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid #6d5aea;
  background-image: linear-gradient(#6b5cc4, #3c2e94);
  cursor: pointer;
  color: #fff;
  text-align: center;
  line-height: 28px;
  font-size: 14px;
  .profileButton:disabled {
    cursor: not-allowed;
    filter: opacity(0.5);
  }
  &:hover {
    background-image: linear-gradient(#8f80ea, #4636ab);
  }
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;