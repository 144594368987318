.header {
  display: flex;
  justify-content: space-between;
}

.btn {
  min-width: 120px;
  padding: 0 12px;
  height: 40px;
}

.detail {
  display: flex;
  margin-top: 32px;
  font-size: 16px;
  gap: 37px;
}

.posterCtn {
  display: flex;
  flex-direction: column;
  max-width: 381px;
  background-color: #211b6d;
  border: 1px solid #3c2e94;
  border-radius: 16px;
  overflow: hidden;
  flex: 1;
}

.box {
  flex: 1;
  display: flex;
  align-items: center;
}

.poster {
  max-width: 100%;
}

.locationIcon {
  width: 26px;
  margin: 12px;
}

.infoCtn {
  color: #fff;
}

.title {
  font-size: 26px;
  margin-top: 8px;
}

.line {
  margin-top: 27px;
  display: flex;
  gap: 56px;
}

.label {
  color: #aeb1bb;
  min-width: 150px;
}

.tipCtn {
  background-color: #211b6d;
  margin-top: 27px;
  border: 1px solid #3c2e94;
  border-radius: 10px;
  color: #aeb1bb;
  display: flex;
  padding: 27px;
  gap: 16px;
}

.warningIcon {
  width: 24px;
  height: 24px;
  position: relative;
  top: 4px;
}

.text {
  flex: 1;
}

.labelWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;