.inputBox {
  display: flex;
  height: 60px;
  line-height: 60px;
  margin: 0 0 20px 0;
  border: 2px solid #999;
  border-radius: 8px;
  font-size: 18px;
  background-color: transparent;
  &:focus-within {
    border: 2px solid #8159e0;
  }
}

.input {
  display: flex;
  flex: 1;
  padding: 0 20px;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border: 0px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  outline: none;
  background-color: transparent;
  &::-webkit-input-placeholder {
    color: #999;
  }
}

.sent {
  font-size: 18px;
  padding: 0 15px 0 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.send {
  font-size: 18px;
  padding: 0 15px 0 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.changeBtn {
  font-size: 18px;
  padding: 0 25px;
  border-radius: 8px 5px 5px 8px;
  background: linear-gradient(270deg, #d4706b 2%, #a953b6 51%, #815ae0 95%);
  cursor: pointer;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;