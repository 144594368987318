.help {
  color: #fff;
}

.qa {
  font-size: 16px;
  margin-bottom: 44px;
  .questionCtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .arrow {
    width: 20px;
    transform: rotate(180deg);
    transition: transform 0.3s;
  }
  .openedArrow {
    transform: rotate(270deg);
  }
  .answer {
    background-color: #211b6d;
    border: 1px solid #3c2e94;
    border-radius: 10px;
    padding: 24px 27px;
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;