.search {
  margin-top: 20px;
  margin-bottom: 28px;
  color: #aeb1bb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form {
  display: flex;
  gap: 30px;
}

.formItem {
  width: 280px;
}

.btn {
  height: 40px;
  width: 140px;
  white-space: nowrap;
}

.addressList {
  margin-bottom: 24px;
}

.paginationCtn {
  display: flex;
  justify-content: center;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;