@border: 1px solid #44318b;

.table {
  border-radius: 14px;
  overflow: hidden;
}

.tbody {
  display: grid;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.th,
.td {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  white-space: nowrap;
  padding: 0 15px;
}

.thead {
  display: grid;
}

.th {
  background-color: #44318b;
}

.td {
  background-color: #210f55;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px dashed #391d89;
}

.fixedRow {
  display: grid;
  width: 100%;
  position: sticky;
  bottom: 0;
  .td {
    background-color: #44318b !important;
  }
}

.noDataCtn {
  position: relative;
  width: 100%;
  height: 330px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;