@import '~@/less/variable.less';

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  height: 100%;
  margin: 0 auto;
  margin-top: 118px;
  margin-bottom: 37px;
  width: 100%;
}

.copyright {
  font-family: ABeeZee;
  font-size: 16px;
  white-space: nowrap;
  color: #b4bacc;
}

.icons {
  display: flex;
  flex: auto;
  justify-content: flex-end;
  gap: 30px;
}

.icon {
  width: 36px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
}

.gitMsg {
  display: none;
  text-align: center;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;