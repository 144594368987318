.customVideo {
  flex: 1;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover {
    .controls {
      visibility: visible;
    }
  }
}

.video {
  width: 100%;
  border-radius: 16px;
  height: 100%;
  object-fit: contain;
  background-color: black;
  &::-webkit-media-controls {
    display: none !important;
  }
  &::-moz-media-controls {
    display: none !important;
  }
  &::-ms-media-controls {
    display: none !important;
  }
  &::-o-media-controls {
    display: none !important;
  }
}

.controls {
  visibility: hidden;
}

.header {
  position: absolute;
  top: 18px;
  left: 18px;
  right: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status {
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 3px 8px;
}

.operateCtn {
  position: relative;
  &:hover {
    .operates {
      visibility: visible;
    }
  }
}

.ellipsisIcon {
  cursor: pointer;
}

.operates {
  visibility: hidden;
  background-color: #2d237e;
  border: 1px solid #3c2e94;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  position: absolute;
  right: 100%;
  top: 0;
  & div {
    cursor: pointer;
    white-space: nowrap;
    &:hover {
      color: #f35caf;
    }
  }
}

.ellipsisIcon {
  width: 24px;
}

.item {
  height: 36px;
  line-height: 36px;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
}

.btn {
  width: 100%;
  margin-top: 24px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;