.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 40px;
  margin-top: 20px;
  margin-bottom: 34px;
}

.title {
  font-size: 18px;
  margin-bottom: 25px;
  font-weight: bold;
}

.btns {
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cancel {
  background-color: transparent;
  background-image: none;
  border: 1px solid #492a93;
  &:hover {
    background-image: none;
  }
}

.btn {
  height: 46px;
  width: 150px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;