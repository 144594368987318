.label {
  margin-bottom: 10px;
}

.select {
  height: 50px;
  line-height: 50px;
  background-color: #211b6d;
  border: 1px solid #3c2e94;
  border-radius: 6px;
  padding: 0 14px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #7a78a3;
}

.form {
  max-height: 590px;
  overflow-y: auto;
  color: #aeb1bb;
  padding: 20px;
  .item {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .label {
    font-size: 16px;
    margin-bottom: 15px;
    color: #fff;
  }
  .line {
    &:hover {
      background-color: #3b3191;
    }
  }
}

.arrow {
  width: 24px;
  height: 24px;
}

.openedArrow {
  transform: rotate(180deg);
}

.selected {
  color: #fff;
}

.currency {
  background-color: #3b3191;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  padding: 3px;
  color: #fff;
  margin-right: 12px;
}

.text {
  margin-right: 16px;
}

.currencyIcon {
  width: 25px;
  height: 25px;
  margin-right: 8px;
  border-radius: 50%;
}

.line {
  color: #aeb1bb;
  font-size: 14px;
  height: 41px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: calc(100% + 40px);
  margin-left: -20px;
  padding-left: 20px;
  .texts {
    vertical-align: baseline;
  }
  .text {
    color: #fff;
    font-size: 18px;
    margin-right: 5px;
  }
}

.list {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;