.ctn {
  align-items: center;
  display: flex;
  background-color: #15135d;
  border-radius: 100px;
  border: 1px solid #302a78;
  height: 46px;
  font-size: 16px;
  position: relative;
  &:hover {
    .dropdownCtn {
      visibility: visible;
    }
    .arrow {
      transform: rotate(180deg);
    }
  }
}

.avatar {
  width: 42px;
  height: 42px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  left: 2px;
}

.account {
  margin-left: 10px;
}

.arrow {
  margin-right: 18px;
  width: 20px;
}

.dropdownCtn {
  position: absolute;
  z-index: 500;
  left: 50%;
  transform: translateX(-50%);
  min-width: 100%;
  top: 44px;
  padding-top: 8px;
  visibility: hidden;
  white-space: nowrap;
}

.dropdown {
  background-color: #15135d;
  border: 1px solid #302a78;
  border-radius: 16px;
  color: #fff;
  padding: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  & div {
    cursor: pointer;
    &:hover {
      color: #f35caf;
    }
  }
}

.signOut {
  background-color: #3c2e94;
  border-radius: 100px;
  height: 32px;
  line-height: 32px;
  margin-top: 6px;
  &:hover {
    color: #fff !important;
  }
}

.item {
  height: 36px;
  line-height: 36px;
  text-align: left;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;