.media {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #8c8ab6;
  .boundBtn {
    color: #9b96c4;
    background-image: linear-gradient(#463d98, #2f2681);
    border: 1px solid #4a3eb2;
  }
}

.icon {
  width: 36px;
  height: 36px;
}

.boundTitle {
  color: #ffffff;
}

.unbindIcon {
  filter: opacity(0.5);
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;