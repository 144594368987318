.imgCtn {
  display: flex;
  gap: 14px;
}

.imgPreview {
  position: relative;
  display: inline-block;
  width: 129px;
  height: 129px;
}

.img {
  width: 129px;
  height: 129px;
  border-radius: 10px;
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  transform: translate(50%, -50%);
}

.btn {
  height: 40px;
  width: 149px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;