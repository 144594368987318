.unselected {
  width: 16px;
  height: 16px;
  border: 1px solid #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.checked {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;