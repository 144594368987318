.input {
  margin: 20px 0;
}

.btn {
  width: 100%;
  margin-top: 15px;
}

.quantityTip {
  width: 100%;
  text-align: center;
}

.btnRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;