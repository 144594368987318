.input {
  width: 100%;
  color: #fff;
  outline: none;
  border: 1px solid #4d4296;
  background-color: #211b6d;
  padding: 15px;
  border-radius: 6px;
  &::-webkit-input-placeholder {
    color: #ccc;
  }
  /* Mozilla Firefox */
  &::-moz-placeholder {
    color: #ccc;
  }
  /* internet Explorer */
  &::-ms-input-placeholder {
    color: #ccc;
  }
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;