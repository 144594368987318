.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.content {
  display: flex;
  border: 1px solid #4d4296;
  background-color: #211b6d;
  border-radius: 6px;
  padding: 12px 14px;
  height: 50px;
  box-sizing: border-box;
}

.label {
  font-size: 16px;
}

.input {
  width: 100%;
  color: #fff;
  outline: none;
  background-color: transparent;
  border: none;
  flex: 1;
  align-self: stretch;
  &::-webkit-input-placeholder {
    color: #7a78a3;
  }
  /* Mozilla Firefox */
  &::-moz-placeholder {
    color: #7a78a3;
  }
  /* internet Explorer */
  &::-ms-input-placeholder {
    color: #7a78a3;
  }
}

.inputCtn {
  display: flex;
  border: 1px solid #4d4296;
  border-radius: 6px;
  align-items: center;
  padding: 0 15px;
  height: 50px;
}

.inputExtra {
  align-self: center;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;