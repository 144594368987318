.table {
  width: calc(100% - 20px);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin: 0 10px;
  tr {
    display: flex;
  }
  td {
    flex: auto;
    padding: 10px 0;
    width: 1px;
  }
  th {
    white-space: nowrap;
    font-size: 12px;
    padding: 10px 0;
    flex: auto;
    width: 1px;
  }
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;