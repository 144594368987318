.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  max-height: 360px;
  overflow-y: auto;
}

.item {
  display: flex;
  font-size: 14px;
  width: 100%;
  align-items: center;
}

.img {
  width: 40px;
  height: 40px;
  border-radius: 9px;
  margin-right: 8px;
}

.btn {
  width: 80px;
  height: 36px;
}

.name {
  flex: 1;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;