.form {
  margin-top: 30px;
  color: #fff;
}

.tabs {
  margin-top: 30px;
}

.addressForm {
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info {
  margin-top: 10px;
  display: inline-grid;
  grid-template-columns: repeat(2, 189px);
  gap: 17px 67px;
  white-space: nowrap;
}

.btn {
  width: 150px;
  margin-top: 20px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;