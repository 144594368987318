.redEnvelopeButton {
  all: initial;
  border-radius: 100px;
  min-width: 195px;
  height: 46px;
  box-sizing: border-box;
  cursor: pointer;
  color: #000;
  text-align: center;
  font-size: 14px;
  background-size: 100% 100%;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: scale(1.05);
  }
  &.gold {
    background-image: url('~@/assets/images/red-envelopes/gold-btn.png');
  }
  &.sliver {
    background-image: url('~@/assets/images/red-envelopes/sliver-btn.png');
  }
  &.bronze {
    background-image: url('~@/assets/images/red-envelopes/bronze-btn.png');
  }
  &:disabled {
    cursor: not-allowed;
    background-image: url('~@/assets/images/red-envelopes/disabled-btn.png');
  }
  .diamond {
    width: 26px;
    margin-left: 5px;
  }
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;