.title {
  font-size: 20px;
  margin-bottom: 22px;
  color: #fff;
}

.form {
  margin-top: 34px;
  color: #aeb1bb;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px 20px;
  font-size: 16px;
}

.withdrawCtn {
  margin-top: 40px;
  color: #fff;
}

.mediaInfo {
  margin-top: 40px;
}

.withdrawHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  color: #aeb1bb;
}

.subTitle {
  font-size: 16px;
}

.medias {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
}

.btn {
  width: 150px;
  height: 40px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;