.button {
  all: initial;
  padding: 5px 55px;
  box-sizing: border-box;
  background-color: #eb51c1;
  cursor: pointer;
  color: #fff;
  text-align: center;
  line-height: 28px;
  font-size: 14px;
  &:hover {
    background-color: #ff62d4;
  }
  &:disabled {
    background-color: #6c47ab !important;
    color: #bd90ec;
    cursor: not-allowed;
  }
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;