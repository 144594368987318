.videoModal {
  position: relative;
  .video {
    width: 850px;
    height: 497px;
    border-radius: 16px;
  }
}

.closeIcon {
  position: absolute;
  right: -27px;
  top: -14px;
  width: 27px;
  cursor: pointer;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;