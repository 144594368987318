.stepItem {
  display: flex;
  gap: 12px;
  color: #aeb1bb;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.index {
  width: 25px;
  height: 25px;
  font-size: 14px;
  color: #fff;
  border: 1px solid #3c2e94;
  background-color: #211b6d;
  text-align: center;
  line-height: 23px;
  box-sizing: border-box;
  border-radius: 50%;
}

.active {
  .index {
    border: 1px solid #fdb1ff;
    background-color: none;
    background-image: linear-gradient(270deg, #fc78ff, #9a6bfe);
  }
  .label {
    color: #fff;
  }
}

.line {
  flex: 1;
  width: 3px;
  background-image: url('~@/assets/images/profile/timeline-line.png');
  background-size: contain;
}

.activeLine {
  background-image: url('~@/assets/images/profile/timeline-line-active.png');
}

.right {
  flex: 1;
}

.label {
  margin-bottom: 14px;
}

.child {
  min-height: 26px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;