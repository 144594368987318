.item {
  border-radius: 16px;
  overflow: hidden;
  background-color: #1c196b;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.imgCtn {
  width: 100%!important;
  height: 230px!important;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.img {
  width: 100%;
}

.colorText {
  font-size: 23px;
  font-weight: bold;
  margin-top: 5px;
  font-family: 'inter';
}

.detail {
  padding: 16px 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.name {
  flex: 1;
  word-break: break-word;
}

.icon {
  width: 18px;
  cursor: pointer;
}

.bottom {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.iconCtn {
  position: relative;
  display: flex;
  align-items: center;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;