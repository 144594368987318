.existAddressSelect {
  color: #fff;
}

.icon {
  width: 24px;
  cursor: pointer;
}

.list {
  max-height: 540px;
  overflow-y: auto;
}

.btnCtn {
  text-align: center;
  margin-top: 24px;
}

.btn {
  width: 240px;
  margin: 0 auto;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;