[data-theme='dark'] .theme-bg-color {
  background-color: #170245;
}
[data-theme='light'] .theme-bg-color {
  background-color: #eef0f8;
}
[data-theme='dark'] .theme-color {
  color: #fff;
}
[data-theme='light'] .theme-color {
  color: #0f0f0f;
}
[data-theme='dark'] .theme-card-bg-color {
  background-color: #1f155a;
}
[data-theme='light'] .theme-card-bg-color {
  background-color: #fff;
}
[data-theme='dark'] .theme-border {
  border: 1px solid #302a78;
}
[data-theme='light'] .theme-border {
  border: 1px solid #fff;
}
[data-theme='dark'] .theme-point-color {
  color: #ffd84f;
}
[data-theme='light'] .theme-point-color {
  color: #f35caf;
}
[data-theme='dark'] .theme-link-color {
  color: #ffd84f;
}
[data-theme='light'] .theme-link-color {
  color: #f35caf;
}
[data-theme='dark'] .content-bg {
  background-color: #210f55;
}
[data-theme='light'] .content-bg {
  background-color: #eef0f8;
}
.colorful-border {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border: 2px solid transparent;
}
[data-theme='dark'] .colorful-border {
  background-image: linear-gradient(#29277d, #32188b 45%), linear-gradient(135deg, #3549ff, #57c3ff, #9f25ff);
}
[data-theme='light'] .colorful-border {
  background-image: linear-gradient(#f3f9ff, #fffdff 45%), linear-gradient(135deg, #ccbfff, #a3d3ff, #ad9fff);
}
[data-theme='dark'] .tab-shadow {
  box-shadow: 0 3px 8px 1px #16086e;
}
[data-theme='light'] .tab-shadow {
  box-shadow: 0 3px 8px 1px #e2e4ff;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}
.btn {
  width: 150px;
  margin: 20px 0;
}
.send {
  cursor: pointer;
  color: #f35caf;
}
