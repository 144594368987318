.info {
  background-image: url('~@/assets/images/profile/assets-info-bg.png');
  background-repeat: no-repeat;
  background-size: 110% 115%;
  background-position: -24px -10px;
  box-sizing: content-box;
  border-radius: 16px;
  padding: 30px;
  margin-bottom: 20px;
}

.titleCtn {
  display: flex;
  align-items: center;
  gap: 5px;
}

.title {
  font-size: 20px;
}

.eyeIcon {
  width: 16px;
  cursor: pointer;
}

.balance {
  font-size: 37.5px;
}

.unitCtn {
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  position: relative;
  &:hover {
    .list {
      display: block;
    }
  }
}

.list {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #15135d;
  border: 1px solid #302a78;
  color: #fff;
  border-radius: 16px;
  padding: 15px;
  z-index: 2;
  white-space: nowrap;
  max-height: 300px;
  overflow-y: auto;
  display: none;
  .item {
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    &:hover {
      color: #f35caf;
    }
  }
}

.arrowIcon {
  width: 24px;
}

.dollar {
  font-size: 16px;
  margin-bottom: 23px;
}

.btn {
  min-width: 135px;
  height: 40px;
  background-color: #fff;
  background-image: none !important;
  color: #0f0f0f;
  font-weight: bold;
  padding: 0 15px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;