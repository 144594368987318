.no-data-color {
  [data-theme='dark'] & {
    color: #5d54ba;
  }
  [data-theme='light'] & {
    color: #898989;
  }
}

.noData {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  .no-data-color;
  gap: 9px;
  .noDataImg {
    width: 88px;
  }
}

.noData.dark {
  color: #5d54ba;
}

.noData.light {
  color: #898989;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;