.settingBtn {
  height: 46px;
  width: 106px;
  position: relative;
  left: 14px;
  border-radius: 6px;
}

.form {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.logoffCtn {
  text-align: center;
  color: #7a78a3;
  margin: 50px 0 22px;
}

.logoff {
  cursor: pointer;
}

.name {
  color: #fff;
  font-size: 18px;
}

.module {
  min-height: 800px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;