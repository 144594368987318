.form {
  margin-top: 30px;
  color: #fff;
}

.tabs {
  margin-top: 30px;
}

.addressForm {
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info {
  display: inline-grid;
  grid-template-columns: repeat(2, 189px);
  gap: 17px 67px;
  white-space: nowrap;
}

.btn {
  width: 150px;
  margin-top: 20px;
}

.title {
  margin-top: 30px;
  margin-bottom: 20px;
}

.img {
  width: 180px;
  margin-bottom: 17px;
  border-radius: 10px;
}

.name {
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: bold;
}

.tip {
  margin-top: 40px;
  color: #9ea2ae;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;