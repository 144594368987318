.uploadArea {
  background-color: #211b6d;
  border: 1px solid #3c2e94;
  border-radius: 10px;
  height: 129px;
  width: 129px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.uploadIcon {
  width: 26px;
}

.hidden {
  display: none;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;