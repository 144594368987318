.datePickerCtn {
  .datePicker {
    height: 50px;
    width: 100%;
    color: #fff;
    background-color: #211b6d;
    border-radius: 6px;
    border: 1px solid #3c2e94;
    box-shadow: none;
    & input {
      color: #fff !important;
    }
    :global {
      .ant-picker-suffix {
        color: #fff;
      }
      .ant-picker-clear {
        background-color: #211b6d;
        color: #fff;
      }
    }
  }
}

@color1: #fff;
@color2: #7a78a3;
@color3: #f35caf;

.popup {
  :global {
    .ant-picker-panel-container {
      background-color: #2d237e;
      border-radius: 10px;
    }
    .ant-picker-cell-in-view {
      color: @color1 !important;
    }
    .ant-picker-content th {
      color: @color1;
    }
    .ant-picker-header {
      color: @color1;
    }
    .ant-picker-header button {
      color: @color1;
    }
    .ant-picker-cell {
      color: @color2;
    }
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
      background-color: #f35caf;
      border-radius: 50%;
    }
    .ant-picker-cell-in-view.ant-picker-cell-today
      .ant-picker-cell-inner::before {
      border: none;
    }
    .ant-picker-footer {
      display: none;
    }
    .ant-picker-panel {
      border: none;
    }
    .ant-picker-super-icon,
    .ant-picker-super-prev-icon,
    .ant-picker-next-icon,
    .ant-picker-header-prev-btn,
    .ant-picker-super-next-icon {
      color: #fff !important;
    }
    .ant-picker-header {
      border-bottom: 1px solid #3b3191;
    }
    .ant-picker-range-arrow {
      visibility: hidden;
    }
    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
        .ant-picker-cell-range-start
      ):not(.ant-picker-cell-range-end):not(
        .ant-picker-cell-range-hover-start
      ):not(.ant-picker-cell-range-hover-end)
      .ant-picker-cell-inner {
      background-color: #3b3191;
    }
    .ant-picker-header-view button:hover {
      color: #fff !important;
    }
  }
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;