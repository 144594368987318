.assetInput {
  font-size: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .label {
    color: #ffffff;
  }
  .extra {
    color: #7a78a3;
  }
}

.main {
  margin-top: 16px;
  display: flex;
  height: 48px;
  background-color: #211b6d;
  border: 1px solid #3c2e94;
  border-radius: 10px;
  margin-bottom: 20px;
  .inputCtn {
    flex: auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    .amount {
      color: #aeb1bb;
      margin-left: 16px;
      margin-right: 5px;
      white-space: nowrap;
    }
    input {
      border: none;
      outline: none;
      flex: auto;
    }
    .maxBtn {
      color: #f35caf;
      margin-right: 20px;
      white-space: nowrap;
    }
  }
  .assetCtn {
    width: 157px;
    border-left: 1px solid #3c2e94;
    .select {
      display: flex;
      align-items: center;
      height: 100%;
      padding-left: 15px;
      cursor: pointer;
    }
    .arrow {
      width: 24px;
      margin-right: 10px;
    }
  }
}

.list {
  width: 157px;
}

.option {
  display: flex;
  align-items: center;
  padding: 5px 0;
  padding-left: 15px;
  margin: 5px 0;
  cursor: pointer;
  &:hover {
    background-color: #3b3191;
  }
}

.coinImg {
  width: 25px;
  border-radius: 50%;
  margin-right: 10px;
}

.coinCode {
  font-size: 18px;
  color: #fff;
  flex: auto;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.clearIcon {
  width: 20px;
  cursor: pointer;
  margin-right: 17px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;