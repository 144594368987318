.label {
  margin-bottom: 10px;
}

.select {
  height: 50px;
  line-height: 50px;
  background-color: #211b6d;
  border: 1px solid #3c2e94;
  border-radius: 6px;
  padding: 0 14px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #7a78a3;
}

.options {
  padding: 10px 0;
}

.option {
  height: 40px;
  line-height: 40px;
  padding: 0 26px;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #3b3191;
  }
}

.selectedOption {
  background-color: #3b3191;
}

.arrow {
  width: 24px;
  height: 24px;
}

.openedArrow {
  transform: rotate(180deg);
}

.selected {
  color: #fff;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;