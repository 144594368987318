.search {
  display: flex;
  align-items: center;
  margin-top: 22px;
  gap: 12px;
  position: relative;
}

.btn {
  height: 40px;
  width: 109px;
}

.select {
  width: 250px;
}

.input {
  width: 330px;
}

.searchIcon {
  width: 18px;
  position: relative;
  top: -4px;
}

.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 23px;
  margin-top: 24px;
}

.switch {
  position: absolute;
  right: 0;
}

.avatar {
  border-radius: 8.5px;
  height: 60px;
  width: 60px;
}

.paginationCtn {
  text-align: center;
  margin-top: 40px;
}

.price {
  font-weight: bold;
}

.alignLeft {
  text-align: left;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;