.textCopy {
  .typography {
    color: inherit;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}

.copyIcon {
  width: 17px;
  cursor: pointer;
  position: relative;
  top: -2px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;