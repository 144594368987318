.switch {
  width: 184px;
  background-color: #15135d;
  border-radius: 26px;
  border: 1px solid #302a78;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    .chainListCtn {
      visibility: visible;
    }
    .arrow {
      transform: rotate(180deg);
    }
  }
}

.selectedChain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  padding: 4px;
}

.chainListCtn {
  position: absolute;
  z-index: 500;
  left: -12px;
  width: 320px;
  top: 44px;
  padding-top: 8px;
  visibility: hidden;
  white-space: nowrap;
}

.chainList {
  background-color: #15135d;
  border: 1px solid #302a78;
  border-radius: 16px;
  color: #fff;
  padding: 15px;
  gap: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  & div {
    cursor: pointer;
    &:hover {
      color: #f35caf;
    }
  }
}

.chainItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
}

.chainInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.icon {
  width: 36px;
  height: 36px;
}

.tick {
  width: 20px;
  margin: 4px;
}

.active {
  background-color: #3c2e94;
  border-radius: 6px;
}

.arrow {
  margin-right: 4px;
  width: 20px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;