.item {
  border-radius: 16px;
  overflow: hidden;
  background-color: #1c196b;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.imgCtn {
  height: 230px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.img {
  width: 100%;
}

.colorText {
  font-size: 23px;
  font-weight: bold;
  margin-top: 5px;
  font-family: 'inter';
}

.detail {
  padding: 16px 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.name {
  flex: 1;
  word-break: break-word;
}

.icon {
  width: 18px;
  cursor: pointer;
}

.bottom {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.popup {
  background-color: #2d237e;
  border: 1px solid #3c2e94;
  border-radius: 10px;
  padding: 20px 0;
  position: absolute;
  width: 124px;
  right: -12px;
  bottom: 100%;
  visibility: hidden;
}

.iconCtn {
  position: relative;
  display: flex;
  align-items: center;
  &:hover .popup {
    visibility: visible;
  }
}

.line {
  height: 36px;
  padding-left: 22px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  &:hover {
    background-color: #3b3191;
  }
}

.popupIcon {
  width: 24px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;