[data-theme='dark'] .theme-bg-color {
  background-color: #170245;
}
[data-theme='light'] .theme-bg-color {
  background-color: #eef0f8;
}
[data-theme='dark'] .theme-color {
  color: #fff;
}
[data-theme='light'] .theme-color {
  color: #0f0f0f;
}
[data-theme='dark'] .theme-card-bg-color {
  background-color: #1f155a;
}
[data-theme='light'] .theme-card-bg-color {
  background-color: #fff;
}
[data-theme='dark'] .theme-border {
  border: 1px solid #302a78;
}
[data-theme='light'] .theme-border {
  border: 1px solid #fff;
}
[data-theme='dark'] .theme-point-color {
  color: #ffd84f;
}
[data-theme='light'] .theme-point-color {
  color: #f35caf;
}
[data-theme='dark'] .theme-link-color {
  color: #ffd84f;
}
[data-theme='light'] .theme-link-color {
  color: #f35caf;
}
[data-theme='dark'] .content-bg {
  background-color: #210f55;
}
[data-theme='light'] .content-bg {
  background-color: #eef0f8;
}
.colorful-border {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border: 2px solid transparent;
}
[data-theme='dark'] .colorful-border {
  background-image: linear-gradient(#29277d, #32188b 45%), linear-gradient(135deg, #3549ff, #57c3ff, #9f25ff);
}
[data-theme='light'] .colorful-border {
  background-image: linear-gradient(#f3f9ff, #fffdff 45%), linear-gradient(135deg, #ccbfff, #a3d3ff, #ad9fff);
}
[data-theme='dark'] .tab-shadow {
  box-shadow: 0 3px 8px 1px #16086e;
}
[data-theme='light'] .tab-shadow {
  box-shadow: 0 3px 8px 1px #e2e4ff;
}
[data-theme='dark'] .task-modal-content {
  background-color: #170245;
  box-shadow: inset 0 0 10px 3px rgba(145, 102, 255, 0.3);
}
[data-theme='light'] .task-modal-content {
  background-color: #fff;
}
[data-theme='light'] .close-icon {
  filter: invert(1);
}
[data-theme='dark'] .content-scrollbar {
  background-color: #452498;
}
[data-theme='light'] .content-scrollbar {
  background-color: #e9e9e9;
}
.taskModal {
  border-radius: 16px;
  background-color: #8b5eff;
  position: relative;
  border-image-slice: 1;
  padding: 1px;
  display: flex;
}
.taskModal .taskModalContent {
  position: relative;
  width: 100%;
  border-radius: 16px;
  background-color: #170245;
  box-shadow: inset 0 0 10px 3px rgba(145, 102, 255, 0.3);
}
.taskModal .close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 20px;
  cursor: pointer;
}
.taskModal .title {
  font-size: 24px;
  text-align: center;
  margin: 21px 0;
  font-weight: bold;
  padding: 0 35px;
}
.taskModal .content {
  padding: 0 25px 25px;
}
.taskModal .content ::-webkit-scrollbar-thumb {
  background-color: #452498;
  width: 4px;
}
@media screen and (max-width: 800px) {
  .taskModal {
    width: calc(100% - 60px) !important;
    max-width: 330px;
  }
  .taskModalContent .title {
    font-size: 16px;
    text-align: center;
    margin: 23px 0 17px;
  }
  .taskModalContent .content {
    background-color: transparent;
    padding: 0 16px 16px;
  }
}
