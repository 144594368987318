.videoControls {
  position: absolute;
  bottom: 12px;
  padding: 0 20px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon {
  width: 24px;
  cursor: pointer;
}

.progressCtn {
  width: 1px;
  flex: 1;
  height: 3px;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 0.5);
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #fff;
  border-radius: 100px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;