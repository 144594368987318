.popover {
  position: relative;
}

.popoverBg {
  position: absolute;
  background-image: url('../../assets/images/footer/popover-bg.png');
  padding: 10px;
  bottom: 48px;
  background-size: 100% 100%;
  width: 148px;
  height: 160px;
  transform: translateX(-50%);
  left: 50%;
}

.qrCode {
  width: 100%;
}

.hidden {
  visibility: hidden;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;