.switch {
  background-color: #211b6d;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  & > div {
    display: inline-block;
  }
}

.icon {
  width: 24px;
  margin: 4px;
}

.active {
  background-color: #3c2e94;
  border-radius: 6px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;