.imgCtn {
  width: 170px;
  height: 98px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg {
  width: 100%;
  filter: blur(5px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  min-height: 100%;
}

.img {
  width: 100%;
  object-fit: contain;
  max-height: 100%;
  position: absolute;
  z-index: 2;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;