.navigator {
  width: 200px;
  padding: 30px 20px !important;
  font-size: 16px;
}

.navigate {
  margin-bottom: 26px;
  cursor: pointer;
  position: relative;
  font-weight: bold;
  &:last-child {
    margin-bottom: 0;
  }
}

.activeNavigate {
  &::before {
    content: '';
    position: absolute;
    width: 5px;
    height: 34px;
    border-radius: 6px;
    background-color: #f35caf;
    left: -20px;
    top: -5px;
  }
}

.subNavigate {
  padding: 12px 0;
  padding-left: 20px;
  color: #aeb1bb;
  cursor: pointer;
  font-weight: initial;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.activeSubNavigate {
  color: #ffffff;
}

.children {
  display: none;
}

.show {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 20px;
}

.name:hover {
  color: #f35caf;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;