@color1: #fff;
@color2: #aeb1bb;

.tabs {
  :global {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: @color1;
    }
    .ant-tabs-tab {
      color: @color2;
      font-size: 18px;
    }
    .ant-tabs-tab-btn:focus {
      color: @color1;
    }
    .ant-tabs-tab-btn:hover {
      color: @color1;
    }
    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: 1px solid #29277a;
    }
    .ant-tabs-ink-bar {
      background-color: #f35caf;
    }
  }
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;