.inputNumber {
  display: flex;
  height: 40px;
  align-items: center;
  border-top: 1px solid #4d4296;
  border-bottom: 1px solid #4d4296;
  border-radius: 6px;
}

.input {
  height: 40px;
  outline: none;
  border: none;
  background-color: transparent;
  padding-inline: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  flex: auto;
  width: 1px;
  margin: 0 -3px;
}

.btn {
  text-align: center;
  background-image: linear-gradient(
    rgba(53, 35, 132, 0.84),
    rgba(53, 35, 132, 0)
  );
  width: 40px;
  height: 40px;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  border-radius: 6px;
  font-size: 24px;
  border: 1px solid #4d4296;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;