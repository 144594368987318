.table {
  :global {
    .ant-table {
      color: #fff;
      background-color: transparent;
    }
    .ant-table-thead > tr > th {
      background: transparent;
      color: #7a78a3;
      border: none;
    }
    .ant-table-tbody > tr > td {
      border: none;
    }
    .ant-table-column-sorters {
      display: inline-flex;
    }
    .ant-table-cell-row-hover {
      background-color: transparent !important;
    }
    .ant-empty-description {
      color: #fff;
    }
    .ant-table-tbody * {
      background-color: transparent !important;
    }
    .ant-table-row-expand-icon-cell {
      display: none !important;
    }
    .ant-table-column-sorters {
      .active {
        color: #7a78a3;
      }
    }
  }
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;