.tables {
  margin-top: 10px;
}

.amount {
  display: inline-flex;
  align-items: center;
}

.currencyIcon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 8px;
}

.search {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  gap: 24px;
  .select {
    width: 240px;
  }
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;