.label {
  margin-bottom: 14px;
}

.content {
  background-color: #211b6d;
  border: 1px solid #3c2e94;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 23px;
}

.icon {
  width: 18px;
  cursor: pointer;
}

.avatar {
  width: 45px;
  border-radius: 50%;
}

.info {
  font-size: 14px;
  color: #aeb1bb;
}

.settingBtn {
  height: 46px;
  width: 106px;
  position: relative;
  left: 14px;
  border-radius: 6px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;