.search {
  display: flex;
  align-items: center;
  margin-top: 22px;
  gap: 12px;
  position: relative;
}

.searchIcon {
  width: 18px;
  position: relative;
  top: -4px;
}

.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 23px;
  margin-top: 36px;
}

.switch {
  position: absolute;
  right: 30px;
  top: 30px;
}

.avatar {
  border-radius: 8.5px;
  height: 60px;
  width: 60px;
}

.module {
  position: relative;
}

.paginationCtn {
  text-align: center;
  margin-top: 40px;
}

.price {
  font-weight: bold;
}

.alignLeft {
  text-align: left;
}

.transferInBtn {
  padding-left: 32px;
  padding-right: 32px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
}

.left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.select {
  min-width: 170px;
  white-space: nowrap;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;