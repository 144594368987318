.desc {
  margin-top: 20px;
}

.form {
  margin-top: 20px;
  color: #fff;
}

.strong {
  color: #f35caf;
}

.verificationCode {
  margin-top: 20px;
  margin-bottom: 30px;
}

.step3 {
  margin: 30px 0;
}

.btn {
  width: 150px;
  height: 46px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;