.limitInput {
  display: flex;
  align-items: center;
  padding: 0 13px;
  height: 48px;
  border: 1px solid #3c2e94;
  background-color: #211b6d;
  border-radius: 10px;
  .input {
    flex: 1;
    margin-left: 5px;
    // max-width: 185px;
    color: #fff;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
    height: 100%;
    &::-webkit-input-placeholder {
      color: #7a78a3;
    }
    /* Mozilla Firefox */
    &::-moz-placeholder {
      color: #7a78a3;
    }
    /* internet Explorer */
    &::-ms-input-placeholder {
      color: #7a78a3;
    }
  }
}

.tip {
  color: #7a78a3;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;