.recharge {
  margin-bottom: 10px;
  position: relative;
}

.unit {
  color: #fff;
}

.btn {
  width: 110px;
  height: 36px;
}

.infoIcon {
  width: 20px;
}

.extra {
  display: flex;
  align-items: center;
  gap: 4px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;