.btn {
  cursor: pointer;
  color: #fff;
  border-radius: 10px;
  border: 1px solid #3c2e94;
  padding: 5px 28px;
}

.active {
  color: #f35caf;
  border: 1px solid #f35caf;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;