.search {
  margin-top: 22px;
}

.formItem {
  width: 250px;
}

.btn {
  height: 40px;
}

.tableCtn {
  margin-top: 10px;
}

.amount {
  display: inline-flex;
  align-items: center;
}

.currencyIcon {
  width: 25px;
  margin-right: 8px;
}

.tableCtn {
  .extraRow {
    display: flex;
    justify-content: space-between;
    background-color: #211b6d !important;
    border-radius: 10px;
    height: 48px;
    align-items: center;
    padding: 0 30px;
  }
}

.item {
  color: #fff;
}

.label {
  color: #7a78a3;
}

.arrow {
  width: 24px;
  cursor: pointer;
  position: relative;
}

.expandedArrow {
  transform: rotate(180deg);
}

.orderIdCtn {
  display: flex;
  align-items: center;
  gap: 12px;
}

.img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.paginationCtn {
  text-align: center;
  // margin-top: 40px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%);
}

.goodsNameCtn {
  display: flex;
  align-items: center;
  min-width: 200px;
  gap: 10px;
}

.time {
  white-space: nowrap;
}

.module {
  min-height: 820px;
  position: relative;
  padding-bottom: 62px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;