.amount {
  display: flex;
  align-items: center;
  height: 100%;
}

.currencyIcon {
  width: 25px;
  height: 25px;
  margin-right: 8px;
  border-radius: 50%;
}

.from {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.arrow {
  width: 24px;
  cursor: pointer;
  position: relative;
}

.expandedArrow {
  transform: rotate(180deg);
}

.tableCtn {
  .extraRow {
    display: flex;
    background-color: #211b6d !important;
    gap: 100px;
    border-radius: 10px;
    height: 48px;
    align-items: center;
    padding: 0 30px;
  }
}

.item {
  color: #fff;
  display: flex;
  align-items: center;
}

.label {
  color: #7a78a3;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;