.address {
  color: #aeb1bb;
  border-radius: 10px;
  border: 1px solid #3c2e94;
  overflow: hidden;
  margin-bottom: 20px;
  background-color: #15135d;
  &:last-child {
    margin-bottom: 0;
  }
}

.header {
  background-color: #211b6d;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.left {
  gap: 15px;
  display: flex;
  align-items: center;
}

.name {
  color: #fff;
}

.detail {
  padding: 20px;
  color: #fff;
}

.line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.label {
  color: #aeb1bb;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;