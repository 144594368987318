.styledPagination {
  :global {
    .ant-pagination-item {
      background-color: transparent;
      border: 1px solid #302a78;
      border-radius: 6px;
      & a {
        color: #aeb1bb;
      }
    }
    .ant-pagination-item-link,
    .ant-pagination-prev,
    .ant-pagination-next {
      background-color: transparent !important;
      border: 1px solid #302a78 !important;
      & button {
        color: #aeb1bb;
      }
    }
    .ant-pagination-jump-next .ant-pagination-item-link {
      border: none !important;
    }
    .ant-pagination-jump-prev .ant-pagination-item-link {
      border: none !important;
    }
    .ant-pagination-item-active {
      background-color: #302a78;
      border: none;
      & a {
        color: #fff;
      }
    }
    .ant-pagination-jump-next .ant-pagination-item-link {
      border: none;
    }
    .ant-pagination-jump-prev .ant-pagination-item-link {
      border: none;
    }
    .ant-pagination-item-ellipsis {
      color: #fff !important;
    }
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
      background-color: transparent !important;
      border: 1px solid #302a78;
      & button {
        color: #aeb1bb !important;
      }
    }
    .ant-pagination-disabled .ant-pagination-item-link,
    .ant-pagination-disabled:hover .ant-pagination-item-link {
      color: #aeb1bb !important;
    }
  }
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;