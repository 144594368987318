.no-data-color {
  [data-theme='dark'] & {
    color: #5d54ba;
  }
  [data-theme='light'] & {
    color: #898989;
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  .no-data-color;
  gap: 9px;
  min-height: 600px;
}

.noDataImg {
  width: 88px;
}

.hidden {
  display: none;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;