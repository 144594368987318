.shareComponent {
  position: relative;
  display: inline-block;
}

.shareButton {
  all: unset;
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 20px;
  height: 20px;

  img {
    width: 100%;
    height: 100%;
  }
}

.socialMediaPanel {
  position: absolute;
  top: 100%;
  background: #3c2e94;
  color: white;
  border-radius: 10px;
  padding: 20px 40px;
  display: flex;
  gap: 10px;
  z-index: 100;
  display: flex;
  gap: 50px;
}

.bottomLeft {
  right: 0;
}

.bottomRight {
  left: 0;
}

.socialMediaOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.socialMediaOption img {
  width: 40px;
  height: 40px;
}

.socialMediaOption span {
  margin-top: 13px;
  font-size: 14px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;