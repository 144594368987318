.form {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.searchTitle {
  font-size: 20px;
  color: #fff;
  display: flex;
}

.divider {
  margin-top: 20px;
  height: 1px;
  background-color: #29277a;
}

.hideTip {
  color: #7a78a3;
}

.tableBtn {
  width: 110px;
  height: 32px;
}

.table {
  position: relative;
  left: -16px;
}

.kindCtn {
  color: #aeb1bb;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.kind {
  color: #fff;
  font-size: 18px;
  margin-right: 6px;
}

.currencyIcon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 8px;
}

.texts {
  vertical-align: baseline;
  display: inline-block;
}

.toCurrencyDetailBtn {
  cursor: pointer;
  transform: rotate(-90deg);
  width: 20px;
  filter: brightness(0.5);
}

.paginationCtn {
  text-align: center;
  // margin-top: 40px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%);
}

.searchInput {
  width: 185px;
}

.assetsDetail {
  min-height: 690px;
  position: relative;
  padding-bottom: 62px;
}

.amount {
  color: #aeb1bb;
}

.operate {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  justify-content: flex-end;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;