.form {
  margin-top: 32px !important;
}

.formItem {
  margin-bottom: 30px;
  color: #fff;
  font-size: 16px;
}

.space {
  margin-top: 10px;
}

.btn {
  width: 149px;
  height: 40px;
}

.cancel {
  background-image: none;
  background-color: #3c2e94;
  &:hover {
    background-image: none;
  }
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;