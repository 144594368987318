.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  .icon {
    width: 40px;
    height: 40px;
    align-self: center;
    border-radius: 50%;
  }
}

.btn {
  min-width: 135px;
  padding: 0 20px;
}

.left {
  display: flex;
  align-items: baseline;
  gap: 10px;
  font-size: 16px;
}

.amount {
  font-size: 32px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;