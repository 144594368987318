.recharge {
  margin-bottom: 10px;
  position: relative;
}

.buyCrypto {
  position: absolute;
  right: 30px;
  top: 30px;
  height: 36px;
  padding: 0 24px;
}

.steps {
  margin-top: 32px;
}

.step1 {
  margin-bottom: 40px;
}

.step3 {
  background-color: #211b6d;
  border: 1px solid #3c2e94;
  border-radius: 10px;
  padding: 50px;
  font-size: 16px;
  color: #aeb1bb;
}

.qrCode {
  width: 135px;
  height: 135px;
}

.addressLabel {
  color: #aeb1bb;
  margin-top: 26px;
}

.address {
  color: #fff;
  margin-top: 6px;
  margin-bottom: 17px;
}

.items {
  margin-top: 44px;
  display: flex;
  gap: 95px;
}

.amount {
  display: inline-flex;
  align-items: center;
}

.currencyIcon {
  width: 25px;
  height: 25px;
  margin-right: 8px;
  border-radius: 50%;
}

.search {
  margin-top: 20px;
  margin-bottom: 20px;
  .select {
    width: 240px;
  }
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;