.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.title {
  font-size: 20px;
}

.more {
  filter: brightness(0.7);
  font-size: 14px;
  cursor: pointer;
  img {
    width: 18px;
    transform: rotate(-90deg);
    margin-left: -4px;
  }
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;