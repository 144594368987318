.desc {
  margin-top: 32px;
}

.qrCodeCtn {
  background-color: #211b6d;
  border: 1px solid #3c2e94;
  border-radius: 10px;
  padding: 14px;
  margin: 28px 0 40px;
  .desc {
    color: #aeb1bb;
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.qrCode {
  width: 136px;
  margin-bottom: 24px;
}

.keyLabel {
  color: #fdfffe;
  font-size: 14px;
}

.key {
  color: #fff;
}

.form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: #fff;
}

.settingBtn {
  height: 46px;
  width: 106px;
  position: relative;
  left: 14px;
  border-radius: 6px;
}

.icon {
  width: 24px;
  position: relative;
  top: -3px;
  cursor: pointer;
}

.save {
  width: 150px;
  height: 40px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;