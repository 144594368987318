.switch {
  width: 100%;
  margin-bottom: 16px;
  background-color: transparent;
  border-radius: 24px;
  border: 1px solid #4d4296;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switchOpen {
  width: 100%;
  margin-bottom: 16px;
  background-color: transparent;
  border-radius: 24px;
  border: 1px solid #4d4296;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .chainList {
    visibility: visible;
  }
  .arrow {
    transform: rotate(180deg);
  }
}

.selectedChain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  box-sizing: border-box;
  padding: 8px 24px;
}

.chainList {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 56px;
  visibility: hidden;
  z-index: 1;
  background-color: #15135d;
  border: 1px solid #302a78;
  border-radius: 16px;
  color: #fff;
  padding: 15px;
  gap: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  & div {
    cursor: pointer;
    &:hover {
      color: #f35caf;
    }
  }
}

.chainItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 8px;
}

.chainInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.icon {
  width: 36px;
  height: 36px;
  margin-right: 16px;
}

.tick {
  width: 20px;
  margin: 4px;
}

.active {
  background-color: #3c2e94;
  border-radius: 6px;
}

.arrow {
  margin-right: 4px;
  width: 20px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;