.videoPreview {
  background-color: #15135d;
  border: 1px solid #302a78;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  .imgCtn {
    position: relative;
    height: 140px;
    width: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
}

.videoInfo {
  padding: 0 8px;
  position: absolute;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
  z-index: 3;
}

.title {
  color: #fdfffe;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  padding: 4px 10px;
  margin: 4px 0;
  word-break: break-all;
}

.playIcon {
  width: 20px;
}

.videoStatus {
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 3px 8px;
}

.space {
  display: flex;
  align-items: center;
  gap: 5px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;