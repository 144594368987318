.navigate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}

.link {
  display: block;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
  color: #fff !important;
  white-space: nowrap;
  &:hover {
    color: #f35caf !important;
  }
}

@media screen and (max-width: 1400px) {
  .navigate {
    gap: initial;
    flex: 1;
  }
}

.activeLink {
  font-weight: bold;
  color: #f35caf !important;
}

.icon {
  width: 24px;
  margin-right: 4px;
}

.colorfulText {
  font-weight: bold;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(to bottom, #ffffff, #ff87c9);
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;