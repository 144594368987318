.lang {
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 6px;
  position: relative;
  padding: 20px 0;
  white-space: nowrap;
  &:hover {
    .langSelect {
      visibility: visible;
    }
  }
}

.arrow {
  width: 20px;
  cursor: pointer;
}

.langSelect {
  visibility: hidden;
  position: absolute;
  z-index: 500;
  background-color: #15135d;
  border: 1px solid #302a78;
  border-radius: 16px;
  color: #fff;
  width: 124px;
  padding: 20px 30px;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
}

.langItem {
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
  white-space: nowrap;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    color: #f35caf;
  }
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;