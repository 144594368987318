.wrapper {
  aspect-ratio: 2/1;
  border: 1px solid #4d4296;
  border-radius: 6px;
  padding: 20px;
  text-align: center;
  font-size: 14px;
  & div {
    text-align: left;
  }
}

.qrCode {
  aspect-ratio: 1/1;
  width: 120px;
  margin-top: 15px;
  margin-bottom: 24px;
}

.tip {
  color: #f35caf;
  margin-top: 22px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;