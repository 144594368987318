.label {
  margin-bottom: 10px;
}

.select {
  height: 50px;
  border: 1px solid #4d4296;
  background-color: #211b6d;
  border-radius: 6px;
  padding: 12px 14px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #7a78a3;
}

.selectItem {
  background-color: #302671;
}

.arrow {
  width: 24px;
  height: 24px;
}

.selected {
  color: #fff;
}

.content {
  padding-top: 0;
}

.tip {
  font-size: 14px;
  color: #f35caf;
  padding: 20px;
  padding-top: 0;
  max-width: 992px;
  margin-top: 20px;
}

.left {
  display: flex;
  gap: 10px;
}

.name {
  color: #fff;
  font-size: 16px;
}

.chainCode {
  color: #9ea2ae;
  font-size: 14px;
}

.right {
  color: #9ea2ae;
  text-align: right;
}

.list {
  padding: 20px 0;
  max-height: 400px;
  overflow-y: auto;
  padding-top: 0;
  min-height: 200px;
}

.line {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background-color: #3b3191;
  }
}

.item {
  font-size: 14px;
  .name {
    margin-right: 8px;
  }
  .chainCode {
    color: #9ea2ae;
  }
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;