.search {
  margin-top: 22px;
}

.formItem {
  width: 250px;
}

.btn {
  height: 40px;
}

.tableCtn {
  margin-top: 10px;
}

.amount {
  display: inline-flex;
  align-items: center;
}

.currencyIcon {
  width: 25px;
  margin-right: 8px;
}

.paginationCtn {
  text-align: center;
  // margin-top: 40px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%);
}

.module {
  min-height: 820px;
  position: relative;
  padding-bottom: 62px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;