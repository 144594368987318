.baseInfo {
  display: flex;
  gap: 20px;
}

.right {
  padding: 10px 0;
}

.upload {
  font-size: 20px;
  color: #fff;
}

.img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}

.id {
  margin-top: 4px;
  margin-bottom: 14px;
  color: #aeb1bb;
  display: flex;
}

.icon {
  width: 26px;
}

.avatarCtn {
  position: relative;
  cursor: pointer;
}

.camera {
  position: absolute;
  width: 30px;
  bottom: -12px;
  left: 50%;
  transform: translate(-50%);
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;