.creations {
  color: #fff;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
}

.select {
  min-width: 154px;
}

.btn {
  height: 40px;
}

.pointIcon {
  width: 14px;
}

.reward {
  display: flex;
  align-items: center;
  gap: 8px;
}

.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px 20px;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 23px;
  position: relative;
  min-height: 100px;
}

.paginationCtn {
  text-align: center;
  margin-top: 40px;
}

@primary-color: #8159E0;@btn-primary-color: #333333;@btn-border-radius-base: 8px;@btn-border-radius-sm: 8px;@link-color: #333333;@link-hover-color: #848484;@menu-item-color: #333333;@dropdown-selected-color: #000000;@layout-body-background: transparent;@layout-header-background: transparent;@layout-header-height: 102px;@height-lg: 48px;